<template>
  <div class="abnormal">
    <NavBar name="设备管理"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.name" placeholder="输入设备名称">
            <template slot="pre">设备名称:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput
            v-model="formInline.facility_number"
            placeholder="输入设备名称"
          >
            <template slot="pre">设备编号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../assets/img/icon/search.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item label="">
          <myButton @click="visible = true" :accessId="93801">
            <span>新增设备</span>
            <template slot="preImage">
              <img src="../../unit/img/zj.png" alt="" />
            </template>
          </myButton>
        </el-form-item>
      </el-form>

      <div class="public-table">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="设备编号"
            prop="facility_number"
          ></el-table-column>
          <el-table-column label="设备名称" prop="name"></el-table-column>
          <el-table-column label="设备图片" prop="operation_tpl">
            <template #default="{ row }">
              <el-image
                v-if="row.images.length > 0"
                :preview-src-list="row.images"
                title="查看"
                :src="require('@/assets/img/icon/xianshi.png')"
              />
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
            label="绑定商铺"
            prop="merchants_stall_number"
          ></el-table-column>
          <el-table-column label="设备描述" prop="message"></el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <pop tips="编辑" :accessId="93802" @click="edit(row)">
                <img class="icon" src="@/assets/img/icon/edit.png" alt />
              </pop>
              <pop tips="删除" :accessId="93803" @click="delDev(row)" popLeft>
                <img class="icon" src="@/assets/img/icon/deleted.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="formInline.pageSize"
          :current-page="formInline.page"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="setType ? '新增设备' : '编辑设备'"
      :visible.sync="visible"
      @close="diaclose"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="100px" :model="form">
        <el-form-item label="设备编号">
          <el-input
            class="public-input"
            v-model="form.facility_number"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" :rules="{ required: true }">
          <el-input
            class="public-input"
            v-model="form.name"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="绑定商铺">
          <el-select
            v-model="form.merchants_stall_id"
            filterable
            remote
            placeholder="请输入关键词"
            :remote-method="getshoplist"
            @change="handleselect"
          >
            <el-option
              v-for="(item, index) in stallList"
              :key="index"
              :value="item.merchants_stall_id"
              :label="item.merchants_stall_number"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备图片">
          <div
            class="upload-imgx-box"
            v-for="(item, index) in form.images"
            :key="index"
          >
            <img class="upload-img" :src="item" alt="" />
            <span @click="deleteImg(index)">x</span>
          </div>

          <el-upload
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="filechange"
          >
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="设备描述">
          <el-input
            type="textarea"
            class="public-input"
            v-model="form.message"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <myButton @click="visible = false">取消</myButton>
        <myButton @click="setDev" type="primary" :disabled="!form.name" left
          >确定</myButton
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "refund",
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      tableData: [],
      form: {
        images: [],
        name: "",
        merchants_stall_id: "",
        message: "",
        facility_id: "",
        facility_number: "",
      },
      visible: false,
      setType: true,
      srcList: [],
      stallList: [],
    };
  },
  created() {
    this.getList();
    this.getshoplist();
  },
  methods: {
    getList() {
      this.$request.HttpGet("/facility/list", this.formInline).then((res) => {
        if (res.data) {
          this.tableData = res.data.list || [];
          this.tableData.forEach((item) => {
            if (item.images) {
              item.images = JSON.parse(item.images);
            }
          });
          this.total = res.data.total;
        }
      });
    },
    getshoplist(query) {
      this.stallList = [];
      this.$request
        .HttpGet("/contract/stallList", { merchants_stall_number: query })
        .then((res) => {
          if (res.data) {
            this.stallList = res.data.list;
          }
        });
    },
    currentChange(page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    filechange(file, filelist) {
      let obj = new FormData();
      obj.append("file", file.raw);
      this.$request.HttpPost("/system/upload/upImg", obj).then((res) => {
        this.form.images.push(
          process.env.VUE_APP_IMG_URL + res.data.fileInfo.hostUrl
        );
      });
    },
    deleteImg(index) {
      this.form.images.splice(index, 1);
    },
    handleselect(e) {
      let data = this.stallList.find((item) => item.merchants_stall_id == e);
      this.form.merchants_stall_number = data
        ? data.merchants_stall_number
        : "";
      // this.form.merchants_stall_id = e.merchants_stall_id;
    },
    edit(row) {
      this.setType = false;
      for (let i in this.form) {
        //深拷贝
        const temp = JSON.parse(JSON.stringify(row));
        this.form[i] = temp[i];
      }
      this.getshoplist("");
      this.visible = true;
    },
    setDev(row) {
      let url = this.setType ? "/facility/add" : "/facility/edit";
      this.$request.HttpPost(url, this.form).then((res) => {
        this.$common.notifySuccess("操作成功");
        this.visible = false;
        this.getList();
      });
    },
    delDev(row) {
      this.$myconfirm("确认删除该设备").then((res) => {
        this.$request
          .HttpPost("/facility/delete", { ids: row.facility_id })
          .then((res) => {
            this.$common.notifySuccess("删除成功");
            this.getList();
          });
      });
    },
    diaclose() {
      for (let i in this.form) {
        if (i == "images") {
          this.form[i] = [];
        } else {
          this.form[i] = "";
        }
      }
      this.setType = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.autoFlex {
  display: flex;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;

    .totalAmout {
      margin-right: 15px;
    }
  }
}

.upload-imgx-box {
  position: relative;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: inline-block;

  .upload-img {
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    right: -10px;
    top: -10px;
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(110, 108, 108);
    border-radius: 50%;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
}

.el-image {
  width: 16px;
}
</style>
