<template>
  <div class="abnormal">
    <NavBar name="操作日志"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="date"
            @change="timeChange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formInline.worker_id"
            clearable
            placeholder="请选择操作人"
          >
            <el-option
              v-for="item in workerList"
              :key="item.user_id"
              :label="item.name"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyInput
            v-model="formInline.operation_tpl"
            placeholder="输入操作模块"
          >
            <template slot="pre">操作模块:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyButton title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../assets/img/icon/search.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>

      <div class="public-table">
        <el-table
          :data="tableData"
          v-loading="loading"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column label="操作人" prop="worker_name"></el-table-column>
          <el-table-column
            label="操作模块名称"
            prop="operation_tpl"
          ></el-table-column>
          <el-table-column
            label="操作详情"
            prop="operation_detail"
          ></el-table-column>
          <el-table-column label="操作时间" prop="create_at"></el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="formInline.pageSize"
          :current-page="formInline.page"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "refund",
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        beginTime: "",
        endTime: "",
        operation_tpl: "",
        worker_id: "",
      },
      total: 0,

      tableData: [],
      workerList: [],
      date: "",
      loading: false,
    };
  },
  created() {
    //do something after creating vue instance
    this.getList();
    this.getWorkerList();
  },
  methods: {
    getWorkerList() {
      this.$request
        .HttpGet("/worker/list", { pageNum: 1, pageSize: 10000 })
        .then((res) => {
          this.workerList = res.data.list;
        });
    },
    timeChange(date) {
      this.formInline.beginTime = date ? date[0] : "";
      this.formInline.endTime = date ? date[1] : "";
    },
    getList() {
      this.loading = true;
      this.$request
        .HttpGet("/operation_log/list", this.formInline)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.tableData = res.data.list || [];
            this.total = res.data.total;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange(page) {
      this.formInline.pageNum = page;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.autoFlex {
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    align-items: center;
    .totalAmout {
      margin-right: 15px;
    }
  }
}
</style>
